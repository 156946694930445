@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '/src/global.scss';

.custom-scroll {
  max-height: 90vh;
  overflow-y: auto;

  /* Hide scrollbar */
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
}

/* Hide scrollbar for Webkit browsers */
.custom-scroll::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.custom-card-margin {
  @media (min-width: map-get($grid-breakpoints, sm)) {
    margin: 0 2rem;
  }
}
