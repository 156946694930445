.popupModalBody {
  max-height: 60vh;
}

.popupModalBodyHeader {
  margin-left: 1rem;
}

.scrollableDataSources {
  max-height: 50vh;
}

.customMargin {
  margin-top: 1.7rem !important;
}
