@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '/src/global.scss';

.headerContainer {
  height: 65px;
  border-bottom: 1px solid $gray-300;
  margin-top: 13px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding-left: 15px;
  padding-right: 15px;

  @media (max-width: map-get($grid-breakpoints, sm)) {
    padding: 0 2rem 0 1rem;
  }

  .customLogo {
    max-width: 60px;
    max-height: 30px;
  }

  .customWidth {
    width: 20px;
  }

  .customDropdownButton {
    @media (max-width: map-get($grid-breakpoints, sm)) {
      max-width: 150px;
    }
  }

  .customInput {
    width: calc(100% - 2rem);
  }

  .customDropdown {
    max-height: 50vh;
    overflow-y: none;
    right: -0.5rem;
    @media (max-width: map-get($grid-breakpoints, sm)) {
      max-width: 250px;
    }

    .customDropdownList {
      height: calc(45vh - 4.5rem);
      overflow-y: scroll;
      border-top: 1px solid $gray;
    }
  }
}

.textWrapper {
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.25em;

  // voyager title in header needs be smaller on smaller devices else it takes up lot of space
  @media (max-width: 480px) {
    font-size: 1rem;
  }

  @media (max-width: 400px) {
    font-size: 0.75rem;
  }
}

.skeletonHeader {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  height: 70px;
  border-bottom: 1px solid $gray-200;
}
