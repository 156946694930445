@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '/src/global.scss';

.privacyAgreementLink {
  color: $black;
  &:hover {
    color: $purple400;
    transition: 0.3s color ease-in-out;
    text-decoration: underline;
  }
}

.customModalButton {
  width: 150px;
}

@media (min-width: map-get($grid-breakpoints, sm)) {
  .customModalBody {
    height: 100px;
  }
}
