@import '/src/global.scss';

.FilePathLink {
  color: $primary;
  font-style: normal;
  line-height: 24px;
  font-size: 16px;
}

.textUnderline {
  text-decoration: underline;
}

.clickableLink {
  &:hover {
    @extend .#{$cursorPointerClass};
    @extend .textUnderline;
  }
}
