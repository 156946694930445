@import '../../global.scss';

.pillWrapper {
  gap: 15px;
}

.pill {
  gap: 8px;
  background: $gray;
  color: $gray-700;
  font-size: 14px;
  line-height: 23px;
  max-width: fit-content;
}

.copyIcon {
  font-size: 12px;
  line-height: 20px;
  max-width: none;
  background: $gray-200;
}

.textUnderline:hover {
  text-decoration: underline;
}
