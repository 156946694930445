@import '../../global.scss';

.chatHistoryComponent {
  width: 260px;
  left: 0;
}

.sidebarDrawerContainer {
  transition: 0.5s;
  width: 260px;
  background-color: $lightBlue;

  &.openContainer {
    left: 0;
    z-index: 9999;
    overflow-y: scroll;
  }

  &.closeContainer {
    left: -260px;
    z-index: 9999;
  }
}

.sidebarDrawerOverlay {
  z-index: 9998;
  transition: 0.5s;

  &.openSideBarDrawerOverlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.6;
  }

  &.closedSideBarDrawerOverlay {
    top: inherit;
    left: inherit;
    right: inherit;
    bottom: inherit;
    opacity: 0;
  }
}
