@import '../../global.scss';

.ui.large.progress {
  background: linear-gradient(to right, white 0%, #a38dff 50%, #7061f8 100%);
  border-radius: 0 0 3px 3px;
}

.result-card__top-section.result-card__top-section--detailView {
  display: none;
}

.progressContainer .ui.progress .bar {
  content: '';
  display: block;
  width: 7px !important;
  height: 7px !important;
  border: 1px solid red;
  border-radius: 50% !important;
  margin-left: 1.7%;
  background: white !important;
  min-width: 0;
}

.result-card.detailCardView--wrapper .detailCardView--innerWrapper {
  overflow: hidden;
}

.slidesImagesContainer
  > .slideNavigation
  .slide-controls__detailedView
  .navigationText {
  color: $purple;
}

.result-card.detailCardView--wrapper .slide-arrows .ui.button.rightArrow img,
.result-card.detailCardView--wrapper .slide-arrows .ui.button.leftArrow img {
  filter: invert(24%) sepia(69%) saturate(3858%) hue-rotate(238deg)
    brightness(94%) contrast(95%);
}

.result-card.detailCardView--wrapper .detailCardView--innerWrapper:hover,
.result-card.detailCardView--wrapper
  .detailCardView--innerWrapper.detailCardView--cardHover {
  box-shadow: none !important;
}

.slideImgWrapper.slideContainer {
  border: 1px solid $gray-300;
  border-radius: 0px;

  .ui.placeholder {
    display: block;
  }
}

.slideNavigation {
  padding-top: 1px;
}

.progressContainer {
  height: 8px !important;
  box-shadow: 0 0 0.25rem 0 rgba(51, 51, 51, 0.3);
}

.result-card-grid.result-card.detailCardView--wrapper {
  height: 100% !important;
}

.result-card.detailCardView--wrapper
  .detailCardView--innerWrapper:hover
  .slide-arrows
  button,
.result-card.detailCardView--wrapper
  .detailCardView--innerWrapper.detailCardView--cardHover
  .slide-arrows
  button {
  background: $purple100 !important;
}

.detailedView--bottomWrapper {
  display: none !important;
}

.slidesImagesContainer > .slideImgWrapper.slideContainer {
  cursor: default;
}

@media (min-width: 1920px) {
  .customDocvizContainer {
    margin-bottom: 2rem;
  }
}

@media (min-width: 2400px) {
  .customDocvizContainer {
    margin-bottom: 4rem;
  }
}

.customDocvizContainer {
  img[class*='ui image'][class*='slideViewThumbnail'] {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}
