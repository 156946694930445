@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';

.Summary {
  color: #809fb8;
  font-size: 16px;
  line-height: 23px;

  @media (max-width: map-get($grid-breakpoints, sm)) {
    .text-ellipsis {
      max-width: 300px;
    }
  }

  // temp fix
  .text-ellipsis {
    max-width: 40rem;
  }
}
