@import '/src/global.scss';

.customScrollBar {
  scrollbar-width: auto;

  &::-webkit-scrollbar {
    width: $scrollbarWidth;
    background-color: $scrollbarColor;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray-400;
    border-radius: $scrollbarThumbRadius;
    transition: background-color 1s ease-in-out;
  }

  @media (hover: hover) {
    &:hover::-webkit-scrollbar-thumb {
      background-color: $gray-500;
    }
  }
}
