@import '../../global.scss';

.contentWrapper {
  max-height: calc(100vh - 65px);
}

.dvh-100 {
  height: 100dvh;
}

.skeletonHeader {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  height: 80px;
  border-bottom: 1px solid $gray-200;
}
