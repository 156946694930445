@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';

// For additional variables, see
// node_modules/bootstrap/scss/_variables.scss
$gray-200: #f2f2f2;
$gray-300: #d4d4d4;
$gray-400: #b1b1b1;
$gray-500: #535353;
$gray-700: #323232;
$darkGray: #7c878e;
$lightGray: #fcfdfe;
$gray: #e3e9ee;
$purple: #a96cdd;
$purple100: #f7f1fd;
$purple200: #e9ddf6;
$purple300: #a96cdd;
$purple400: #894bd1;
$lightRed: #fc34001a;
$red: #fc3400;
$lightGreen: #20c9ac1a;
$green: #20c9ac;
$lightOrange: #ffa0431a;
$orange: #ffa043;
$primary: $purple;
$black: #000000;
$white: #ffffff;
$lightBlue: #dbeafe;
$secondary: shade-color($purple, 80%);
$scrollbarWidth: 0.5rem;
$scrollbarColor: $gray-200;
$scrollbarThumbColor: $purple300;
$scrollbarThumbRadius: 0.275rem;

$theme-colors: map-merge(
  $theme-colors,
  (
    'primary': $primary,
    'secondary': $secondary,
  )
);

$cursorPointerClass: cursorPointer;

.#{$cursorPointerClass} {
  cursor: pointer;
}

// Custom helper classes
// Might be better to create custom
// mixins instead.
.max-width-20 {
  max-width: 20px;
}

.cursor-pointer {
  cursor: pointer;
}

.text-decoration-underline {
  text-decoration: underline;
}

label,
input[type='checkbox'],
input[type='radio'] {
  cursor: pointer;
}

.w-split {
  --w-split-bar-shadow-disable: none !important;
  @media (max-width: map-get($grid-breakpoints, md)) {
    flex-direction: column !important;
  }
}

.w-split-horizontal > .w-split-bar.disable {
  display: none;
}

.customSplitBar {
  box-shadow: none !important;
  background: transparent;
  height: 2vh;
  top: 30px;
  left: 0;
  right: 0;
  margin-top: 15px;
  float: right;
  justify-content: right;
  position: sticky !important;
  &::before,
  &::after {
    top: 0;
    font-size: 22px;
    font-weight: bold;
    line-height: 1.2;
    width: 10px !important;
    position: absolute;
    box-shadow:
      inset 0 1px 0 0 rgba(0, 0, 0, 0.5),
      0 1px 0 0 rgba(0, 0, 0, 0.5) !important;
  }
}

// Bootstrap default variables
@import '~bootstrap/scss/bootstrap.scss';
