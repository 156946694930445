@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '/src/global.scss';

.bgGray200 {
  background-color: $gray-300 !important;
}

.chatHistoryTitle {
  max-width: 12rem;
}

.sidebarContainer {
  min-width: 0;
  height: 100%;
  @media (min-width: map-get($grid-breakpoints, md)) {
    min-width: 300px;
  }
}

.customListItem:hover {
  background-color: $gray-200;
}

.loadMore {
  background-color: $gray-200;
  &:hover {
    background-color: $gray-300;
  }
}

.loadMoreIcon {
  background-color: $purple300;
  border-radius: 50%;
}
