@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '/src/global.scss';

.customDropdown {
  max-height: 50vh;
  overflow-y: auto;

  .form-control {
    width: calc(100% - 2rem);
  }

  .list-unstyled {
    height: calc(45vh - 4.5rem);
    overflow-y: scroll;
    border-top: 1px solid $gray;
  }
}
