.TextLink {
  border: 1px solid #ebeaed;
  padding: 8px var(--sizes-size-12, 12px);
  gap: 8px;
  max-width: fit-content;
  color: #323232;
  font-size: 12px;
  line-height: 20px;
  cursor: pointer;
}
