@import '/src/global.scss';
@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';

.circle {
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
}

.hideCaret .form-select:disabled {
  --bs-form-select-bg-img: none !important;
  background-color: var(--bs-body-bg) !important;
  border: none;
}

.avatar {
  border-radius: 50%;
}

.customTable {
  max-height: 45vh;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }
}

.customTableBody {
  max-height: 40vh;
}

.searchBar {
  [class^='SearchBarWithTypeAheadstyles__Wrapper'] {
    width: 100%;

    .ui.selection.dropdown[class*='SearchBarWithTypeAheadstyles__ScopePicker'] {
      display: none;
    }

    [class*='SearchBarWithTypeAheadstyles__SearchInputWrapper']
      [class*='SearchBarWithTypeAheadstyles__SearchInput']
      input[type='text'] {
      width: 30rem !important;

      @media (max-width: map-get($grid-breakpoints, sm)) {
        width: 23rem !important;
      }
    }

    [class*='SearchBarWithTypeAheadstyles__TypeAheadWrapper'] {
      margin-left: 10px;
      width: 80%;
    }
  }
}

.tableWrapper {
  thead tr th {
    color: $darkGray;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 2;
  }

  tbody tr {
    height: 4.5rem;

    td:first-child {
      color: $darkGray;
    }
  }
}

.saveBtn {
  width: 9.17rem;
}

$colors: $purple, $purple400, $red, $green, $orange, $gray-700, $black;

@for $i from 1 through 1000 {
  .user-#{$i} {
    background-color: nth($colors, random(length($colors)));
  }
}

.defaultAvatar {
  width: 32px;
  height: 32px;
}

.userRoleSelect {
  text-align: -webkit-right;
}

.firstColumn {
  width: 25%;
}

.secondColumn {
  width: 45%;
}

.thirdColumn {
  width: 10%;
}

.defaultColumn {
  width: 20%;
}

.deleteUserLoadingIcon {
  color: $purple400;
}
