.skeleton {
  display: block;
  background-color: rgba(0, 0, 0, 0.11);
  animation: Skeleton-keyframes-pulse 1.5s ease-in-out 0.5s infinite;
}

.skeletonCircle {
  border-radius: 100%;
}

.skeletonRect {
  height: auto;
  transform: scale(1, 0.6);
  margin-top: 0;
  border-radius: 4px;
  margin-bottom: 0;
  transform-origin: 0 60%;
}

.skeletonRect:empty:before {
  content: '\00a0';
}

@keyframes Skeleton-keyframes-pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
