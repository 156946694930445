.labelOnTop {
  color: var(--colors-grays-black, #000);
  font-size: 12px;
  line-height: normal;

  &__field {
    font-size: 16px;
    line-height: 23px;
    overflow-wrap: break-word;
  }
}
