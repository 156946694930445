.ImageWithText {
  > div > {
    div:first-child {
      color: var(--grays-black, var(--colors-grays-black, #000));
      font-size: 18px;
      line-height: 23px;
    }

    div:nth-child(2) {
      color: #909090;
      font-size: 16px;
      line-height: 23px;
    }
  }
}
