@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '/src/global.scss';

.customHomeLoadingSkeleton {
  margin-left: 2rem;
  max-width: 300px;
}

@media (min-width: map-get($grid-breakpoints, sm)) {
  .customHomeLoadingSkeleton {
    margin-left: 6rem;
    max-width: 400px;
  }
}
