@import '../../global.scss';

.circle {
  width: 1.8rem;
  height: 1.8rem;
  background-color: $purple;
}

.inprogress {
  background-color: $lightOrange;
  color: $orange;
  width: fit-content;
}

.created {
  background-color: $lightGreen;
  color: $green;
  width: fit-content;
}

.failed {
  background-color: $lightRed;
  color: $red;
  width: fit-content;
}

.deleted {
  background-color: $gray-300;
  color: $gray-700;
  width: fit-content;
}

.tableWrapper {
  thead tr th {
    color: $darkGray;
  }

  tbody tr {
    height: 4.5rem;

    td:first-child {
      color: $darkGray;
    }
  }
}
.workspaceConfigIcon {
  width: 21px;
  height: 21px;
  align-self: center;
}
