@import '../../global.scss';

.sidebarContainer {
  background-color: $gray-200;
  transition: 0.2s;
  width: 70px;

  &.openContainerWidth {
    width: 260px;
  }
}

.menuIconButtonWrapper {
  transition: 0.3s;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.menuItemWrapper {
  height: 40px;
  width: 40px;
  transition: background-color 0.3s;
  cursor: pointer;
  padding: auto;
  border-radius: 50%;
  background: $white;

  &:hover {
    background-color: $purple400;

    * {
      color: white;
    }
  }

  &.expanded {
    border-radius: 100px;
    padding: 15px;
  }

  &.active {
    background: $purple400;
  }
}
